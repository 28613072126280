<template>
  <!--begin::Companies show for TransporterAdmin (My Senders)-->
  <div>
    <div class="manager-title">
      <div>
        {{ $t("MY_COMPANY.MY_SENDERS") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box pt-6">
      <v-form ref="formMySender" lazy-validation class="form">
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.TRADE_NAME") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.TradeName"
              readonly
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.FISCAL_NAME") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.FiscalName"
              readonly
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.FISCAL_ID") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.FiscalID"
              readonly
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.BRAND_NAME") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.BrandName"
              readonly
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.CONTACT_NAME") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.ContactName"
              readonly
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.CONTACT_EMAIL") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.ContactEmail"
              readonly
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.CONTACT_PHONE") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.ContactPhone"
              readonly
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/phone-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.FULL_ADDRESS") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.Street"
              readonly
            />
          </div>

          <div class="form-group col-lg-2">
            <label>
              {{ $t("COMPANIES.POSTAL_CODE") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.PostalCode"
              readonly
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("COMPANIES.CITY") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.City"
              readonly
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("COMPANIES.PROVINCE") }}
            </label>
            <v-text-field
              class="form-control bg-white border-0 pl-0"
              v-model="CompanyData.ProvinceName"
              readonly
            />
          </div>
        </div>
      </v-form>

      <!-- begin::Action buttons -->
      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary"
          @click="$router.push('/manager/myprofile?ActiveTab=mysenders')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>
      </div>
      <!-- end::Action buttons -->
    </div>
  </div>
  <!-- end::Companies edit for Superadmin -->
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import CompanyService from "@/core/services/api/v2/company.service";

export default {
  name: "MySendersShow",

  data() {
    return {
      CompanyID: 0,
      CompanyData: {}
    };
  },

  mounted() {
    this.CompanyID = parseInt(this.$route.params.id);
    this.loadCompanyData();
  },

  methods: {
    loadCompanyData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      CompanyService.getMySendersProfile(this.CompanyID).then(response => {
        if (response === 404) {
          this.onClickBackButton();
        } else {
          this.CompanyData = response;

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    onClickBackButton() {
      this.$router.push("/manager/myprofile?ActiveTab=mysenders");
    }
  }
};
</script>
